/**
 * We'll load Lodash, a modern JavaScript utility library delivering modularity, performance & extras.
 * Lodash makes JavaScript easier by taking the hassle out of working with arrays, numbers, objects, strings, etc.
 * Lodash’s modular methods are great for: Iterating variables, Manipulating & testing values, Creating composite functions.
 */
window._ = require('lodash');

/**
 * Solve problems with old browsers with es6 promise polyfill
 */
require('es6-promise').polyfill();

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

jQuery(document).ready(function ($) {
    // Your custom code...
    console.log('File successfully loaded!');

    // Ajax values for request 
    const ajaxUrl = ajax_bones.ajaxurl;
    const ajaxNonce = ajax_bones.nonce;

    // Init wow Js
    var wow = new WOW({
        boxClass:     'wow',      // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        mobile:       false,       // trigger animations on mobile devices (default is true)
        live:         false,       // act on asynchronously loaded content (default is true)
        resetAnimation: false,     // reset animation on end (default is true)
    });
    wow.init();

    // News slider
    var swiper = new Swiper('.swiper-news', {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        centeredSlides: true,
        initialSlide: 1,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 5,
                centeredSlides: false,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            }
        }
    });

    // Sostenibillidad home Slider
    var swiper = new Swiper('.swiper-sustainability', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    });

    // Main 
    $('.mobile-icon').click(function(){
        $('.main-mobile').slideToggle();
    });

    $('#search').click(function(){
        $('.form-search').toggle();
    });

    $('#search-web').click(function(){
        $('.form-search').toggle();
    });

    $('.main-mobile ul .menu-item-has-children').click(function (e) { 
        $(this).find('.sub-menu').slideToggle();
    });


    //generalidades-rse
    $('.articles-programs .programs-read-more').on('click', function(){
        $(this).parent().parent().find('.info').css('height','auto');
    })

    // Animated map
    if( $('#map-countries').length ){
        var map = new Datamap({
            element: document.getElementById('map-countries'),
            responsive: false,
            setProjection: function(element) {
                var projection = d3.geo.equirectangular()
                    .center([100, 0])
                    .rotate([175, 0])
                    .scale(500)
                    .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
                var path = d3.geo.path()
                .projection(projection);
                return {path: path, projection: projection};
            },
            fills: {
                COL: '#003c83',
                USA: '#2170b7',
                HND: '#2170b7',
                SLV: '#4f94c6',
                PAN: '#2170b7',
                PER: '#2170b7',
                CHL: '#68aac1',
                UNKNOWN: 'rgb(0,0,0)',
                defaultFill: '#bfd9ef'
            },
            data: {
                COL: {
                    fillKey: 'COL',
                    icon: '/wp-content/uploads/2019/12/tool-col.png'
                },
                USA: {
                    fillKey: 'USA',
                    icon: '/wp-content/uploads/2019/12/tool-eeuu.png'
                },
                HND: {
                    fillKey: 'HND',
                    icon: '/wp-content/uploads/2019/12/tool-honduras.png'
                },
                SLV: {
                    fillKey: 'SLV',
                    icon: '/wp-content/uploads/2019/12/tool-salvador-1.png'
                },
                PAN: {
                    fillKey: 'PAN',
                    icon: '/wp-content/uploads/2019/12/tool-panama.png'
                },
                PER: {
                    fillKey: 'PER',
                    icon: '/wp-content/uploads/2019/12/tool-peru.png'
                },
                CHL: {
                    fillKey: 'CHL',
                    icon: '/wp-content/uploads/2019/12/tool-chile.png'
                }
            },
            geographyConfig: {
                hideAntarctica: true,
                borderWidth: 1,
                borderOpacity: 1,
                borderColor: '#FDFDFD',
                highlightFillColor: '#00731f',
                popupTemplate: function(geo, data) {
                    return ['<img src="'+ data.icon +'" src="Tool Map">'].join('');
                },
                popupOnHover: false,
                highlightOnHover: true
            }
        });
        // map.labels();
    }

    // News slider
    var swiperNational = new Swiper('.swiper-national-services', {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination-where',
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 5,
                centeredSlides: false,
                initialSlide: 1,
                navigation: false
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
                navigation: {
                    nextEl: '',
                    prevEl: '',
                }
            }
        },
        on: {
            slideChange: function () {
                setTimeout(() => {
                    let activeSlide = $('.swiper-slide-active').find('article span.map-iframe');
                    $('.map-result .map').html($(activeSlide).eq(0).html());
                }, 500);
            },
        }
    });

    
    // Contact form 7 
    if($('.wpcf7').length){
        $('.wpcf7-submit').val('Envíar');
        var wpcf7Elm = document.querySelector( '.wpcf7' ); 
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            $("html, body").animate({
                scrollTop: $('.form').offset().top 
            }, 2000);
        }, false );
    }

    $('#country-fil').on('change', function(e) {
        e.preventDefault();
        var id_parent_cat = $($(this)).val();

        if(id_parent_cat){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_departments',
                    nonce: ajaxNonce,
                    data: id_parent_cat,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                data = response.data;
                console.log(data.length)
                if(data.length > 0){
                    $("#departments").show();
                    $("#department-fil").html('<option value="">Seleccione...</option>');
                    data.forEach(function(element){
                        $("#department-fil").append('<option value='+ element.term_id +'>'+ element.name +'</option>');
                        $('#department-fil').prop('disabled', false);
                    });
                }else{
                    $("#department-fil").val('');
                    $("#municipality-fil").val('');
                    $("#departments").hide();
                    $("#cities").hide();

                    var id_child_cat = id_parent_cat

                    if(id_child_cat){
                        $.ajax({
                            url: ajaxUrl,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                action: 'get_info_location',
                                nonce: ajaxNonce,
                                data: id_child_cat,
                            },
                        })
                        .done(function (response) {
                            console.log(response.data);
                            $('#total_result span').html(response.data.length);
                            if(response.data[0].map){
                                $('.map-result .map').html(response.data[0].map);
                            }
                            var post = '';
                            $.each(response.data, function(key, value) {
                                var url_ext = '';
                                var image = '';
                                if(value.url_site ){
                                    url_ext = `<a href="${value.url_site}" target="_blank">Sitio web</a>`;
                                }
                                if( value.image ){
                                    image = `<img src="${value.image}" alt="">`;
                                }else{
                                    image = `<img src="/wp-content/uploads/2019/12/bg-default-location-n.png" alt="">`;
                                }
                                post += `
                                <div class="swiper-slide">
                                    <article>
                                        <figure>
                                            ${image}
                                            <div class="overlay">
                                                <h2>${value.title}</h2>
                                            </div>
                                        </figure>
                                        <div class="info">`;
                                        if(value.address) {
                                            post += `<div class="address">
                                                        <h5>Dirección</h5>
                                                        <p>${value.address}</p>
                                                    </div>`;
                                        };
                                        if(value.phone) {
                                            post += `<div class="phone">
                                                        <h5>Teléfono</h5>
                                                        <p>${value.phone}</p>
                                                    </div>`;
                                        }                                
                                            
                                        post += `${url_ext}
                                            <span class="map-iframe" style="display: none;">
                                                ${value.map}
                                            </span>
                                        </div>
                                    </article>
                                </div>
                                `;
                            });
                            $(".swiper-national-services .swiper-wrapper").html(post);
                            swiperNational.update();
                        })
                        .fail(function (response) {
                            console.log('error');
                        });
                    }
                }
            })
            .fail(function (response) {
                console.log('error');
            });
        }

    });

    // Get all related municipalities
    $('#department-fil').on('change', function(e) {
        e.preventDefault();
        var id_parent_cat = $($(this)).val();

        if(id_parent_cat){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_municipalities',
                    nonce: ajaxNonce,
                    data: id_parent_cat,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                data = response.data;
                console.log(Object.keys(data).length)
                if(Object.keys(data).length > 0){
                    $("#cities").show();
                    $("#municipality-fil").html('<option value="">Seleccione...</option>');
                    Object.entries(data).forEach(function(element){
                        $("#municipality-fil").append('<option value='+ element[1].term_id +'>'+ element[1].name +'</option>');
                        $('#municipality-fil').prop('disabled', false);
                    });
                }else{
                    $("#cities").val('');
                    $("#cities").hide();
                }
            })
            .fail(function (response) {
                console.log('error');
            });
        }

    });

     // Get all locations by municipality
    $('#municipality-fil').on('change', function(e) {
        e.preventDefault();

        var id_child_cat = $($(this)).val();

        if(id_child_cat){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_info_location',
                    nonce: ajaxNonce,
                    data: id_child_cat,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                console.log(response.data);
                $('#total_result span').html(response.data.length);
                if(response.data[0].map){
                    $('.map-result .map').html(response.data[0].map);
                }

                $.each(response.data, function(key, value) {
                    var url_ext = '';
                    var image = '';
                    if(value.url_site ){
                        url_ext = `<a href="${value.url_site}" target="_blank">Sitio web</a>`;
                    }
                    if( value.image ){
                        image = `<img src="${value.image}" alt="">`;
                    }else{
                        image = `<img src="/wp-content/uploads/2019/12/bg-default-location-n.png" alt="">`;
                    }
                    var post = `
                    <div class="swiper-slide">
                        <article>
                            <figure>
                                ${image}
                                <div class="overlay">
                                    <h2>${value.title}</h2>
                                </div>
                            </figure>
                            <div class="info">`;
                            if(value.address) {
                                post += `<div class="address">
                                            <h5>Dirección</h5>
                                            <p>${value.address}</p>
                                        </div>`;
                            };
                            if(value.phone) {
                                post += `<div class="phone">
                                            <h5>Teléfono</h5>
                                            <p>${value.phone}</p>
                                        </div>`;
                            }                                
                                
                               post += `${url_ext}
                                <span class="map-iframe" style="display: none;">
                                    ${value.map}
                                </span>
                            </div>
                        </article>
                    </div>
                    `;
                    $(".swiper-national-services .swiper-wrapper").html(post);
                });
                swiperNational.update();
            })
            .fail(function (response) {
                console.log('error');
            });
        }
    });

    /**
     * Modal programs
     */
    /* $('.programs-read-more').click(function (e) { 
        e.preventDefault();
        let  postID = $(this).data('id');
        
        if(postID){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_program_by_id',
                    nonce: ajaxNonce,
                    data: postID,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                let post = `
                    <h3>${response.data.post_title}</h3>
                    <div class="info">
                        ${response.data.post_content}
                    </div>
                `;
                $('#modal-programs .info-art ').html(post);
                $('#modal-programs').modal('show');
            })
            .fail(function (response) {
                console.log('error');
            });
        }
    });*/

    /**
     * Get info sites Virtual office
     */
    $('#sites-info').select2({
        placeholder: 'Seleccione una opción...'
    });

    $('#sites-info').on('change', function(e) {
        e.preventDefault();

        var id_site_post = $(this).val();
        if(id_site_post){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_info_sites',
                    nonce: ajaxNonce,
                    data: id_site_post,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                if( response.data.site_office_link){
                    var postOffice = `
                        <h4>${ response.data.post_title }</h4>
                        <a href="${ response.data.site_office_link }" target="_blank" rel="noopener noreferrer">Ir a la oficina virtual</a>
                    `;
                }else{
                    var postOffice = `
                        <p>No se encontararon resultados.</p>
                    `;
                }
                $('.info-link').html(postOffice);
                $('.info-link').show('slow');
            })
            .fail(function (response) {
                console.log('error');
            });
        }

    });

    /**
     * Gallery Services
     */
    if( jQuery(".gallery-services-img").length !== 0 ) {

        $('.gallery-services-img').magnificPopup({
            type: 'image',
            gallery:{
                enabled:true,
                tCounter: '%curr% de %total%'
            }
        });

        AOS.init();
    }

    /**
     * Faqs
     */
    if( $(".faqs").length !== 0 ) {
        
        (function(){
            $('#accordionFaqs').collapse({
            toggle: true
          })
        });

        $('.content-collapse').on('shown.bs.collapse', function () {
            $target = $(this).attr('id');
            $('#'+$target).prev().find('span').removeClass('dashicons-arrow-right').addClass('dashicons-arrow-down');
            console.log('abre');
        });

        $('.content-collapse').on('hide.bs.collapse', function () {
            $target = $(this).attr('id');
            console.log($target);

            $('#'+$target).prev().find('span').removeClass('dashicons-arrow-down').addClass('dashicons-arrow-right');
            console.log('cierra');

        })
    }
});